import React, { useEffect, useState } from "react";
import "tailwindcss/tailwind.css";
import { Select, MultiSelect, Button, Modal, Text } from "@mantine/core";
import { showNotification } from "@mantine/notifications";
import { DatePicker } from "@mantine/dates";
import { QuillDeltaToHtmlConverter } from 'quill-delta-to-html';
import { apiWrapWithErrorWithData } from "../../utilities/apiHelpers";
import {
  createAction,
  updateAction,
  actionDropDown,
} from "../../utilities/apis/recovery";
import { useSelector } from "react-redux";
import { selectUserData } from "../../redux/selectors";
import TemplatesList from "../../components/TemplateManager/TemplatesList";
import { formatDate } from "../../utilities/utilities";
import NoticeList from "./NoticeList";
import EmailList from "./EmailList";

function AddActionModal({ formState, setFormState, errors, fetchActions, dueDetailId }) {
  const [templateModal, setTemplateModal] = useState({
    isModalOpen: false,
    templateType: "",
  });
  const convertDeltaToHtml = (deltaOps) => {
    const converter = new QuillDeltaToHtmlConverter(deltaOps, {});
    return converter.convert();
  };
  const [emailTemplateName, setEmailTemplateName] = useState("");
  const [noticeTemplateName, setNoticeTemplateName] = useState("");
  const currentUser = useSelector(selectUserData) || {};
  const [dropDown, setDropDown] = useState({
    fromEmails: [],
    toEmails: [],
    ccEmails: [],
    attachments: [],
    recovery: {},
    invoiceData: [],
  });
  const fetchDropDown = async () => {
    const response = await apiWrapWithErrorWithData(actionDropDown({ dueDetailId }));
    console.log("response from fetchDropDown", response);
    if (response.success) {
      const fromEmails = [...(response.data.fromEmails), currentUser.email];
      setDropDown((prevState) => ({
        ...prevState,
        fromEmails: [...new Set(fromEmails)],
        toEmails: response.data.toEmails.length === 0 ? ['toEmail@example.com'] : [...new Set(response.data.toEmails)],
        ccEmails: response.data.ccEmails.length === 0 ? ['ccEmail@example.com'] : response.data.ccEmails,
        attachments: response.data.attachments,
        recovery: response.data.recovery,
        invoiceData: response.data.invoiceData,
      }));
      setFormState((prevState) => ({
        ...prevState,
        toEmail: [response.data.debtorEmail.email],
        fromEmail: currentUser.email,
      }));
    }
  };
  useEffect(() => {
    fetchDropDown();
  }, [formState.isModalOpen]);
  const handleModalClose = () => {
    setFormState((prevState) => ({
      ...prevState,
      isModalOpen: false,
      isEdit: false,
      id: "",
      executionDate: "",
      preferrence: "",
      emailTemplateId: null,
      emailTemplate: null,
      emailTemplateContent: {},
      noticeTemplateId: null,
      noticeTemplate: null,
      noticeTemplateContent: {},
      fromEmail: "",
      toEmail: [],
      ccEmail: [],
      attachments: [],
      errors: {},
    }));
    setEmailTemplateName("");
    setNoticeTemplateName("");
  };
  const validateForm = () => {
    const errors = {};
    if (!formState.executionDate) {
      errors.executionDate = "Execution date is required";
    }
    if (!formState.preferrence) {
      errors.preferrence = "Preferrence is required";
    }
    if (!formState.emailTemplateId) {
      showNotification({
        color: "red",
        title: "Resource Missing",
        message: "Email template is required",
      });
    }
    if (formState.preferrence === "notice" && !formState.noticeTemplateId) {
      errors.noticeTemplateId = "Notice template is required";
      showNotification({
        color: "red",
        title: "Resource Missing",
        message: "Notice template is required",
      });
    }
    if (!formState.fromEmail) {
      errors.fromEmail = "From email is required";
    }
    if (formState.toEmail.length === 0) {
      errors.toEmail = "To email is required";
    }
    setFormState((prevState) => ({
      ...prevState,
      errors,
    }));
    return Object.keys(errors).length === 0;
  };
  const getTomorrowDate = () => {
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(today.getDate() + 1);
    return tomorrow;
  };
  const handleFormSubmit = async () => {
    if (validateForm()) {
      if (formState.isEdit) {
        const response = await apiWrapWithErrorWithData(
          updateAction(formState)
        );
        if (response.success) {
          fetchActions();
        }
      } else {
        const response = await apiWrapWithErrorWithData(
          createAction(formState)
        );
        if (response.success) {
          fetchActions();
        }
      }
      handleModalClose();
    }
  };
  const initializeValues = (template) => {
    const recovery = dropDown.recovery;
    const replacements = {
      '#entity type#': recovery.entityType,
      '#registered name#': recovery.registeredName,
      '#authorised representative name#': recovery.representativeName,
      '#authorised representative email id#': recovery.representativeEmail,
      '#registered address#': recovery.registeredAddress,
      '#corporate address#': recovery.corporateAddress,
      '#business poc#': recovery.businessPOC,
      '#individual name#': recovery.individualName,
      '#email idl#': recovery.emailID,
      '#father name#': recovery.fathersName,
      '#residence address#': recovery.residenceAddress,
      '#pan no.#': recovery.panNo,
      '#phone no.#': recovery.phoneNo,
    };

    return template.map(item => {
      if (replacements.hasOwnProperty(item.insert)) {
        return {
          insert: replacements[item.insert] || '',
        };
      }
      return item;
    });
  };
  const addInvoiceDataToEmail = () => {
    const invoiceData = dropDown.invoiceData;
    console.log("invoiceData", invoiceData);
    let tableHTML = '<table border="1" cellpadding="5" cellspacing="0" style="border-collapse: collapse;">';
    tableHTML += '<tr><th>Invoice Number</th><th>Invoice Date</th><th>Invoice Amount</th><th>Credit Period</th><th>Penalty Rate</th><th>Total Pending Amount</th></tr>';

    let totalPendingAmountSum = 0;

    invoiceData.forEach(invoice => {
      tableHTML += `<tr>
                        <td>${invoice.invoiceNumber}</td>
                        <td>${formatDate(invoice.invoiceDate)}</td>
                        <td style="text-align: right;">${invoice.invoiceAmount}</td>
                        <td>${invoice.creditPeriod} days</td>
                        <td>${invoice.penaltyRate}%</td>
                        <td style="text-align: right;">${invoice.totalPendingAmount}</td>
                      </tr>`;
      totalPendingAmountSum += invoice.totalPendingAmount;
    });

    tableHTML += `<tr>
                    <td colspan="5" style="text-align: right;"><strong>Total</strong></td>
                    <td style="text-align: right;"><strong>${totalPendingAmountSum}</strong></td>
                  </tr>`;
    tableHTML += '</table>';
    console.log("tableHTML", tableHTML);
    return tableHTML;
  };
  return (
    <>
      {templateModal.isModalOpen && (
        <Modal
          overflow="inside"
          opened
          onClose={() => {
            setTemplateModal((prevState) => ({
              ...prevState,
              isModalOpen: false,
            }));
          }}
          // size="calc(80vw)"
          fullScreen
        >
          {templateModal.templateType === "Email" ? (
            // <TemplatesList
            //   showNewTemplateBtn={false}
            //   templateType={templateModal.templateType}
            //   useTemplateFunc={(template) => {
            //     const content = initializeValues(template.quillDelta.ops);
            //     let htmlContent = convertDeltaToHtml(content);
            //     setFormState((prevState) => ({
            //       ...prevState,
            //       [templateModal.templateType === "Email" ? "emailTemplateId"
            //         : "noticeTemplateId"]: template.id,
            //       [templateModal.templateType === "Email" ? "emailTemplateContent"
            //         : "noticeTemplateContent"]: { ops: content },
            //     }));
            //     setTemplateModal((prevState) => ({
            //       ...prevState,
            //       isModalOpen: false,
            //     }));
            //     if (templateModal.templateType === "Email") {
            //       setEmailTemplateName(template.name);
            //       console.log("dropDown.invoiceData", dropDown.invoiceData);
            //       if (dropDown.invoiceData.length > 0) {
            //         console.log("dropDown.invoiceData", addInvoiceDataToEmail());
            //         htmlContent = htmlContent.replace(/#invoice table#/g, `<br><p>Invoice Details: </p>${addInvoiceDataToEmail()}`);
            //       }
            //       setFormState((prevState) => ({
            //         ...prevState,
            //         emailTemplateHTML: htmlContent,
            //       }));
            //     } else {
            //       setNoticeTemplateName(template.name);
            //     }
            //   }}
            // />
            <EmailList
              isViewOnly={true}
              useTemplateFunction={(template) => {
                setFormState((prevState) => ({
                  ...prevState,
                  emailTemplateHTML: template.htmlContent,
                  emailTemplateId: template.id,
                }));
                setEmailTemplateName(template.name);
                setTemplateModal((prevState) => ({
                  ...prevState,
                  isModalOpen: false,
                }));
                console.log("Email template -------------------->>>>", template);
              }}
            />
          ) : (
            <NoticeList
              isViewOnly={true}
              useTemplateFunction={(template) => {
                setFormState((prevState) => ({
                  ...prevState,
                  noticeTemplateId: template.id,
                }));
                setNoticeTemplateName(template.name);
                setTemplateModal((prevState) => ({
                  ...prevState,
                  isModalOpen: false,
                }));
                console.log("Notice template -------------------->>>>", template);
              }}
            />
          )}


        </Modal>
      )}
      <Modal
        title={formState.isEdit ? "Update Action" : "Add New Action"}
        size="calc(80vw)"
        opened={formState.isModalOpen}
        closeOnClickOutside={false}
        onClose={handleModalClose}
      >
        <div className="grid grid-cols-3 gap-4">
          <DatePicker
            required
            minDate={getTomorrowDate()}
            placeholder="Pick execution date"
            label="Execution Date"
            value={formState.executionDate}
            onChange={(value) => {
              setFormState((prevState) => ({
                ...prevState,
                executionDate: value,
              }));
            }}
            error={errors.executionDate}
          />
          <Select
            required
            className="inputCustom w-full ml-0 leading-normal"
            label="preference"
            placeholder="Select action"
            data={[
              { value: "email", label: "Email" },
              { value: "notice", label: "Notice" },
            ]}
            value={formState.preferrence}
            onChange={(value) => {
              setFormState((prevState) => ({
                ...prevState,
                preferrence: value,
              }));
            }}
            error={errors.preferrence}
          />
          <Select
            required
            searchable
            creatable
            className="inputCustom w-full ml-0 leading-normal"
            label="From Email"
            placeholder="Select initiator email"
            value={formState.fromEmail}
            getCreateLabel={(value) => `+ Add ${value}`}
            data={dropDown.fromEmails || []}
            onCreate={(value) => {
              setDropDown((prevState) => ({
                ...prevState,
                fromEmails: [...prevState.fromEmails, value],
              }));
            }}
            onChange={(value) => {
              setFormState((prevState) => ({
                ...prevState,
                fromEmail: value,
              }));
            }}
            error={errors.fromEmail}
          />
          <MultiSelect
            required
            searchable
            creatable
            label="To Email"
            placeholder="Select receiver emails"
            data={[...dropDown.toEmails]}
            value={formState.toEmail}
            getCreateLabel={(value) => {
              return `+ Add ${value}`;
            }}
            onCreate={(value) => {
              setDropDown((prevState) => ({
                ...prevState,
                toEmails: [...prevState.toEmails, value],
              }));
            }}
            onChange={(value) => {
              setFormState((prevState) => ({
                ...prevState,
                toEmail: value,
              }));
            }}
            error={errors.toEmail}
          />
          <MultiSelect
            searchable
            creatable
            label="CC Email"
            placeholder="Select cc emails"
            data={[...dropDown.ccEmails]}
            value={formState.ccEmail}
            getCreateLabel={(value) => `+ Add ${value}`}
            onCreate={(value) => {
              setDropDown((prevState) => ({
                ...prevState,
                ccEmails: [...prevState.ccEmails, value],
              }));
            }}
            onChange={(value) => {
              setFormState((prevState) => ({
                ...prevState,
                ccEmail: value,
              }));
            }}
            error={errors.ccEmail}
          />
          <MultiSelect
            searchable
            creatable
            label="Attachment"
            placeholder="select attachments"
            // data={dropDown.attachments.map(attachment => ({ value: attachment.id, label: attachment.fileName }))}
            data={[...dropDown.attachments]}
            value={formState.attachments}
            onChange={(value) => {
              setFormState((prevState) => ({
                ...prevState,
                attachments: value,
              }));
            }}
            error={errors.attachments}
          />
        </div>
        {formState.preferrence && (
          <>
            <div className="flex justify-center mt-10">
              {formState.preferrence === "email" ? (
                <Text className="text-center" color="blue">
                  Choose the email template for the action.
                </Text>
              ) : (
                <Text className="text-center" color="blue">
                  Choose the email template and notice template for the
                  action.
                </Text>
              )}
            </div>
            <div className="grid grid-cols-6 gap-4 m-10">
              <Button
                className="col-span-1"
                onClick={() =>
                  setTemplateModal({
                    isModalOpen: true,
                    templateType: "Email",
                  })
                }
              >
                Email Template
              </Button>
              <Text className="col-span-5 ml-10">
                {emailTemplateName
                  ? emailTemplateName
                  : formState.emailTemplate
                    ? formState.emailTemplate
                    : "No Template have been selected for Email."}
              </Text>
              {formState.preferrence === "notice" && (
                <>
                  <Button
                    className="col-span-1"
                    onClick={() =>
                      setTemplateModal({
                        isModalOpen: true,
                        templateType: "Notices",
                      })
                    }
                  >
                    Notice Template
                  </Button>
                  <Text className="col-span-5 ml-10">
                    {noticeTemplateName
                      ? noticeTemplateName
                      : formState.noticeTemplate
                        ? formState.noticeTemplate
                        : "No Template have been selected for Notice."}
                  </Text>
                </>
              )}
            </div>
          </>
        )}

        <div className="flex justify-center mt-5">
          <Button color="green" onClick={handleFormSubmit}>
            {formState.isEdit ? "Update" : "Save"}
          </Button>
        </div>
      </Modal>
    </>
  );
}

export default AddActionModal;