import React, { useState, useEffect } from "react";
import { formatDate } from "../../utilities/utilities";
import { Table, ActionIcon, Modal, Button, Switch } from "@mantine/core";
import { Edit, BrandGoogle, BrandWindows } from "tabler-icons-react";
import { showNotification } from "@mantine/notifications";
import { apiWrapWithErrorWithData } from "../../utilities/apiHelpers";
import {
    getEmailTemplateList,
    saveEmailTemplate,
    lockEmailTemplate,
    toggleActionAddInvoice,
    getSpecifcActionData,
} from "../../utilities/apis/recovery";
import Editor from "../Editor";
import GoogleEditor from "./GoogleEditor";
import OutlookEditor from "./OutlookEditor";

const EmailComponent = ({ recoveryId, haveActionRight = true }) => {
    const [templateData, setTemplateData] = useState({
        emailList: [],
        isModalOpen: false,
        content: {},
        templateId: null,
        actionId: null,
        html: null,
        executionDate: null,
        isEmailLocked: false,
        isInvoiceAdded: false,
    });
    const [gEditorData, setGEditorData] = useState({
        isViewOnly: false,
        isModalOpen: false,
        isFullScreen: false,
        isApiCalled: false,
        body: null,
        subject: null,
        toEmail: [],
        ccEmail: [],
        bccEmail: [],
        actionId: null,
    });
    const [oEditorData, setOEditorData] = useState({
        isModalOpen: false,
        actionId: null,
        toEmail: [],
        fromEmail: "",
        ccEmail: [],
        bccEmail: [],
        body: null,
    });
    const fetchEmailTemplates = async () => {
        const response = await apiWrapWithErrorWithData(
            getEmailTemplateList({ recoveryId })
        );
        if (response.success) {
            setTemplateData((prevState) => ({
                ...prevState,
                emailList: response.emailList,
            }));
        }
    };
    const handleEmailTemplateLock = async () => {
        if (!templateData.html) {
            showNotification({
                color: "red",
                title: "Email Template Error",
                message: "Please do some changes in template before locking it.",
            });
            return;
        }
        const response = await apiWrapWithErrorWithData(
            lockEmailTemplate({
                actionId: templateData.actionId,
                emailTemplateContent: templateData.content,
                emailTemplateHTML: templateData.html,
                isEmailLocked: true,
            })
        );
        if (response.success) {
            fetchEmailTemplates();
            setTemplateData((prevState) => ({
                ...prevState,
                isModalOpen: false,
                content: {},
                templateId: null,
                actionId: null,
                html: null,
                executionDate: null,
                isEmailLocked: false,
            }));
        }
    };
    const handleEmailTemplateUnlock = async () => {
        const response = await apiWrapWithErrorWithData(
            lockEmailTemplate({
                actionId: templateData.actionId,
                emailTemplateContent: templateData.content,
                emailTemplateHTML: templateData.html,
                isEmailLocked: false,
            })
        );
        if (response.success) {
            fetchEmailTemplates();
            setTemplateData((prevState) => ({
                ...prevState,
                isModalOpen: false,
                content: {},
                templateId: null,
                actionId: null,
                html: null,
                executionDate: null,
                isEmailLocked: false,
            }));
        }
    };
    const dateDiffCal = (data) => {
        const executionDate = new Date(data);
        const currentDate = new Date();
        const differenceInTime = currentDate - executionDate;
        const differenceInDays = Math.ceil(differenceInTime / (1000 * 3600 * 24));
        return differenceInDays !== 1 ? true : false;
    };
    const handleAddingInvoiceData = async () => {
        templateData.isInvoiceAdded = !templateData.isInvoiceAdded;
        const payload = {
            recoveryId,
            actionId: templateData.actionId,
            isInvoiceAdded: templateData.isInvoiceAdded,
        };
        const response = await apiWrapWithErrorWithData(
            toggleActionAddInvoice(payload)
        );
        if (response.success) {
            fetchEmailTemplates();
        }
    };
    const fetchGoogleTemplate = async () => {
        const payload = {
            actionId: gEditorData.actionId,
        };
        const response = await apiWrapWithErrorWithData(
            getSpecifcActionData(payload)
        );
        if (response.success) {
            setGEditorData((prevState) => ({
                ...prevState,
                body: response.data.emailTemplateHTML,
                isModalOpen: true,
            }));
        }
    };
    useEffect(() => {
        fetchEmailTemplates();
    }, [recoveryId]);
    useEffect(() => {
        if (gEditorData.isApiCalled) {
            fetchGoogleTemplate();
            setGEditorData((prevState) => ({
                ...prevState,
                isApiCalled: false,
            }));
        }
    }, [gEditorData.isApiCalled]);
    const handleEmailTemplateSave = async (payload) => {
        const response = await apiWrapWithErrorWithData(
            saveEmailTemplate(payload)
        );
        if(response.success){
            fetchEmailTemplates();
        }
    };
    return (
        <>
            <GoogleEditor
                formState={gEditorData}
                setFormState={setGEditorData}
                useTemplateFunction={(templateContent) => {
                    setGEditorData((prevState) => ({
                        ...prevState,
                        isModalOpen: false,
                    }));
                    const payload = {
                        actionId: gEditorData.actionId,
                        htmlContent: templateContent,
                    };
                    handleEmailTemplateSave(payload);
                }}
            />
            <OutlookEditor formState={oEditorData} setFormState={setOEditorData} />
            <Modal
                title="Edit Email Template"
                overflow="inside"
                opened={templateData.isModalOpen}
                onClose={() => {
                    setTemplateData((prevState) => ({
                        ...prevState,
                        isModalOpen: false,
                        content: {},
                        templateId: null,
                        actionId: null,
                        html: null,
                        executionDate: null,
                        isEmailLocked: false,
                    }));
                }}
                size="calc(80vw)"
            >
                <div className="flex justify-between p-2 mb-5">
                    {haveActionRight ? (
                        !templateData.isEmailLocked ? (
                            <>
                                <Switch
                                    checked={templateData.isInvoiceAdded}
                                    onChange={handleAddingInvoiceData}
                                    label={
                                        templateData.isInvoiceAdded
                                            ? "Hide Invoice Data"
                                            : "Show Invoice Data"
                                    }
                                />
                                <div>
                                    {/* <Button onClick={handleEmailTemplateSave}>Save</Button> */}
                                    <Button className="mx-2" onClick={handleEmailTemplateLock}>
                                        Lock
                                    </Button>
                                </div>
                            </>
                        ) : dateDiffCal(templateData.executionDate) ? (
                            <Button onClick={handleEmailTemplateUnlock}>Unlock</Button>
                        ) : (
                            <div className="font-bold text-[15px] leading-[1.55] text-red-500 text-center">
                                Time limit exceeded to unlock the email template.
                            </div>
                        )
                    ) : null}
                </div>
                <Editor
                    locked={templateData.isEmailLocked || !haveActionRight}
                    content={templateData.content}
                    templateId={templateData.templateId}
                    onContentChange={(content) => {
                        setTemplateData((prevState) => ({
                            ...prevState,
                            content,
                        }));
                    }}
                    onHtmlChange={(content) => {
                        setTemplateData((prevState) => ({
                            ...prevState,
                            html: content,
                        }));
                    }}
                />
            </Modal>

            <div className="m-10">
                {templateData.emailList.length > 0 && (
                    <Table striped highlightOnHover={true}>
                        <thead>
                            <tr>
                                <td>Reference no.</td>
                                <td>Execution Date</td>
                                <td>Name</td>
                                <td>&nbsp;</td>
                            </tr>
                        </thead>
                        <tbody>
                            {templateData.emailList.map((row) => (
                                <tr key={row.id}>
                                    <td>{`action-${row.id}`}</td>
                                    <td>{formatDate(row.executionDate)}</td>
                                    <td>{row.templateName}</td>
                                    <td>
                                        <div className="grid grid-cols-3 gap-0 items-center">
                                            {/* <ActionIcon
                                                title="Edit"
                                                icon="edit"
                                                onClick={() => {
                                                    dateDiffCal(row.executionDate);
                                                    setTemplateData((prevState) => ({
                                                        ...prevState,
                                                        isModalOpen: true,
                                                        content: row.emailTemplateContent,
                                                        templateId: row.emailTemplateId,
                                                        actionId: row.id,
                                                        html: row.emailTemplateHTML,
                                                        executionDate: row.executionDate,
                                                        isEmailLocked: row.isEmailLocked,
                                                    }));
                                                }}
                                            >
                                                <Edit color={!row.isEmailLocked ? "red" : "green"} />
                                            </ActionIcon> */}
                                            <ActionIcon
                                                title="google editor"
                                                onClick={() =>
                                                    setGEditorData((prevState) => ({
                                                        ...prevState,
                                                        actionId: row.id,
                                                        isApiCalled: true,
                                                        isFullScreen: false,
                                                    }))
                                                }
                                            >
                                                <BrandGoogle />
                                            </ActionIcon>
                                            {/* <ActionIcon
                                                title="Outlook editor"
                                                onClick={() =>
                                                    setOEditorData((prevState) => ({
                                                        ...prevState,
                                                        actionId: row.id,
                                                        isModalOpen: true,
                                                        fromEmail: "rana@outlook.com",
                                                    }))
                                                }
                                            >
                                                <BrandWindows />
                                            </ActionIcon> */}
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                )}
            </div>
        </>
    );
};

export default EmailComponent;
