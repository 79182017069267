import React, { useEffect, useState } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import "tailwindcss/tailwind.css";
import {
    Text,
    TextInput,
    Select,
    Button,
    Table,
    ActionIcon,
    Badge,
    Pagination,
    Tabs,
    Modal,
    Textarea,
} from "@mantine/core";
import { ArrowBack, Edit, Trash, Ban, Send } from "tabler-icons-react";
import ActionsPage from "./ActionsPage";
import { formatDate, formatTime, formatNumberWithCommas } from "../../utilities/utilities";
import { apiWrapWithErrorWithData } from "../../utilities/apiHelpers";
import {
    getSpecificRecoveryDetail,
    disableRecovery,
    sendToHigherAuthority,
} from "../../utilities/apis/recovery";
import NoticeComponent from "../../components/Recovery/NoticeComponent";
import EmailComponent from "../../components/Recovery/EmailComponent";
import DocsComponent from "../../components/Recovery/DocsComponent";
import ExpensesTab from "../../components/ExpensesTab/ExpensesTab";
import InvoiceListSection from "../../components/Recovery/InvoiceListSection";
import DisposeComponent from "../../components/Recovery/DisposeComponent";
import ActivityComponent from "../../components/Recovery/ActivityComponent";
import EmailSentComponent from "../../components/Recovery/EmailSentComponent";
import DisposeDisplayComponent from "../../components/Recovery/DisposeDisplayComponent";
import { useSelector } from "react-redux";
import { selectUserData } from "../../redux/selectors";
import { showNotification } from "@mantine/notifications";

const RecoveryDisplayPage = ({ isFromDashboard = false }) => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [recoveryData, setRecoveryData] = useState({});
    const [isViewOnly, setIsViewOnly] = useState(false);
    const [userAccessData, setUserAccessData] = useState({});
    const [activeTab, setActiveTab] = useState("notice");
    const [disposeData, setDisposeData] = useState({
        isModalOpen: false,
        isSubDispose: false,
        id: null,
        reason: "",
        recoveryId: null,
        selectedOption: "",
        totalAmount: null,
        settlementAmount: null,
        settlementDate: null,
        paymentStatus: null,
        closingDate: null,
        approvers: [],
        files: [],
        errors: {},
    });
    const [recoveryAuditData, setRecoveryAuditData] = useState({
        isModalOpen: false,
        recoveryId: null,
        comment: "",
        isNMI: false,
        level: null,
        errors: {},
    });
    const currentUser = useSelector(selectUserData) || {};
    const fetchRecovery = async () => {
        const response = await apiWrapWithErrorWithData(
            getSpecificRecoveryDetail({ recoveryId: id })
        );
        if (response.success) {
            setRecoveryData(response.data.recovery);
            setUserAccessData(response.data.userAccess);
            console.log("this is recovery data - - - - - - - - - - - - - - - - - > ", response.data);
            const disposeData = response.data.recovery.DisposeData
                ? response.data.recovery.DisposeData[0]
                : null;
            setIsViewOnly((response.data.recovery.level === 2 && currentUser.loginAs.name === "Initiator") || (disposeData && disposeData.isClosed) || isFromDashboard);
            if (disposeData) {
                setDisposeData({
                    isModalOpen: false,
                    id: disposeData.id,
                    reason: disposeData.reason,
                    recoveryId: disposeData.recoveryId,
                    selectedOption: disposeData.optSelected,
                    settlementAmount: disposeData.sAmount,
                    settlementDate: disposeData.sDate,
                    paymentStatus: disposeData.cIsPaid,
                    closingDate: disposeData.cDate,
                    approvers: disposeData.approvers,
                    files: [],
                    errors: {},
                });
            }
        }
    };
    useEffect(() => {
        fetchRecovery();
    }, []);
    const handleRecoveryDisable = async () => {
        const response = await apiWrapWithErrorWithData(
            disableRecovery({ recoveryId: recoveryData.id })
        );
        if (response.success) {
            navigate("/app/recovery");
        }
    };
    const handleRecoveryAudit = async () => {
        const errors = {};
        if (!recoveryAuditData.comment) {
            errors.comment = "Comment is required";
        }
        if (Object.keys(errors).length > 0) {
            setRecoveryAuditData({ ...recoveryAuditData, errors });
            return;
        }
        const payload = {
            recoveryId: recoveryData.id,
            comment: recoveryAuditData.comment,
            isNMI: currentUser.loginAs.name === "Editor",
            level: currentUser.loginAs.name === "Editor" ? 1 : 2,
        };
        const response = await apiWrapWithErrorWithData(
            sendToHigherAuthority(payload)
        );
        if (response.success) {
            showNotification({
                title: "Success",
                message: "Recovery sent successfully",
                color: "teal",
            });
            navigate("/app/recovery");
        }
    };
    return (
        <>
            <Modal
                title={
                    currentUser.loginAs.name === "Editor"
                        ? "Send to Initiator"
                        : "Send to Editor"
                }
                overflow="inside"
                opened={recoveryAuditData.isModalOpen}
                closeOnClickOutside={false}
                onClose={() => {
                    setRecoveryAuditData((prevState) => ({
                        ...prevState,
                        isModalOpen: false,
                        recoveryId: null,
                        comment: "",
                        isNMI: false,
                        level: null,
                        errors: {},
                    }));
                }}
                size="calc(50vw)"
            >
                <div className="m-2">
                    <Textarea
                        required
                        minRows={6}
                        label="Comment"
                        placeholder="Write your comment here"
                        value={recoveryAuditData.comment}
                        onChange={(event) => {
                            setRecoveryAuditData({
                                ...recoveryAuditData,
                                comment: event.currentTarget.value,
                            });
                        }}
                        error={recoveryAuditData.errors.comment}
                    />
                </div>
                <div className="flex justify-center mt-8">
                    <Button color="green" onClick={handleRecoveryAudit}>
                        {currentUser.loginAs.name === "Editor" ? "Need More Info" : "Send"}
                    </Button>
                </div>
            </Modal>
            <DisposeComponent
                formState={disposeData}
                setFormState={setDisposeData}
                errors={disposeData.errors}
            />
            {recoveryData.DisposeData && recoveryData.DisposeData.length > 0 && (
                <div className="bg-red-500 text-white p-1 rounded-md shadow-lg min-w-full flex justify-center ">
                    <Text
                        size="xl"
                        weight={700}
                    >{`Disposed - ${recoveryData.DisposeData[0].optSelected}`}</Text>
                </div>
            )}
            <div className="flex flex-row justify-between items-center mt-2">
                <div>
                    <div className="grid grid-cols-2 mt-3 items-center">
                        <div className="font-normal text-[25px] leading-[1.55] text-black">{`Recovery ${recoveryData.id}`}</div>
                        <Badge color="orange" className="mx-5">
                            Created
                        </Badge>
                    </div>
                    <div className="flex flex-row mt-2">
                        <div className="flex flex-row mr-4">
                            <img
                                className="pr-2"
                                src="/assets/images/calendar.svg"
                                alt="calendar"
                            />
                            <span className="font-light text-[12px] leading-[15px] text-[#3E3E3E]">
                                Created on {formatDate(recoveryData.createdAt)}
                            </span>
                        </div>
                        <div className="flex flex-row mr-4">
                            <img
                                className="pr-2"
                                src="/assets/images/clock.svg"
                                alt="clock"
                            />
                            <span className="font-light text-[12px] leading-[15px] text-[#3E3E3E]">
                                {" "}
                                {formatTime(recoveryData.createdAt)}
                            </span>
                        </div>
                    </div>
                </div>
                <div>
                    <div className="grid grid-cols-4 gap-2 mt-3 items-center">
                        <ActionIcon title="Back" onClick={() => navigate(-1)}>
                            <ArrowBack color="gray" />
                        </ActionIcon>
                        {!isViewOnly && (
                            <>
                                <ActionIcon
                                    title="Edit"
                                    onClick={() =>
                                        navigate(`/app/recovery/edit/${recoveryData.id}`)
                                    }
                                >
                                    <Edit color="#46BDE1" />
                                </ActionIcon>
                                {currentUser.loginAs.name === "Editor" &&
                                    recoveryData.DisposeData
                                    ? recoveryData.DisposeData.length === 0 && (
                                        <ActionIcon
                                            title="Dispose"
                                            onClick={() => {
                                                setDisposeData({
                                                    ...disposeData,
                                                    isModalOpen: true,
                                                    recoveryId: recoveryData.id,
                                                    totalAmount: recoveryData.DueDetail
                                                        ? recoveryData.DueDetail[0].recoveryAmount
                                                        : 0,
                                                });
                                            }}
                                        >
                                            <Ban color="orange" />
                                        </ActionIcon>
                                    ) : null}
                                {recoveryData.DisposeData &&
                                    currentUser.loginAs.name === "Editor" &&
                                    recoveryData.DisposeData.length !== 0 && (
                                        <ActionIcon
                                            title="Sub Dispose"
                                            onClick={() => {
                                                setDisposeData({
                                                    ...disposeData,
                                                    isModalOpen: true,
                                                    isSubDispose: true,
                                                    totalAmount: recoveryData.DueDetail
                                                        ? recoveryData.DueDetail[0].recoveryAmount
                                                        : 0,
                                                });
                                            }}
                                        >
                                            <Ban color="orange" />
                                        </ActionIcon>
                                    )}
                                <ActionIcon
                                    title={
                                        currentUser.loginAs.name === "Editor"
                                            ? "Send to Initiator"
                                            : "Send to Editor"
                                    }
                                    onClick={() =>
                                        setRecoveryAuditData({
                                            ...recoveryAuditData,
                                            isModalOpen: true,
                                        })
                                    }
                                >
                                    <Send
                                        color={
                                            currentUser.loginAs.name === "Editor" ? "red" : "green"
                                        }
                                    />
                                </ActionIcon>
                            </>
                        )}
                        {/* <ActionIcon title="Disable" onClick={handleRecoveryDisable}>
                            <Trash color="orange" />
                        </ActionIcon> */}
                    </div>
                    {/* <div className="font-bold text-[15px] leading-[1.55] text-red-500 text-center">
                        Pending since 9 days
                    </div>  */}
                </div>
            </div>

            <div>
                <div
                    className="font-normal text-[20px] leading-[1.55] text-black mt-10"
                    style={{ color: "#46BDE1" }}
                >
                    Debtor Details
                </div>
                <div className="grid grid-cols-4 gap-y-9 gap-x-4 mt-3 mb-9">
                    <div>
                        <div className="font-light text-[16px] leading-[1.55] text-[#595A5B]">
                            Type
                        </div>
                        <div className="font-normal text-[14px] leading-[1.55] text-[#121212]">
                            {recoveryData.type}
                        </div>
                    </div>
                    {recoveryData.type === "Entity" && (
                        <>
                            <div>
                                <div className="font-light text-[16px] leading-[1.55] text-[#595A5B]">
                                    Entity Type
                                </div>
                                <div className="font-normal text-[14px] leading-[1.55] text-[#121212]">
                                    {recoveryData.entityType}
                                </div>
                            </div>
                            <div>
                                <div className="font-light text-[16px] leading-[1.55] text-[#595A5B]">
                                    Registered Name
                                </div>
                                <div className="font-normal text-[14px] leading-[1.55] text-[#121212]">
                                    {recoveryData.registeredName}
                                </div>
                            </div>
                            <div>
                                <div className="font-light text-[16px] leading-[1.55] text-[#595A5B]">
                                    Authorised representative Name
                                </div>
                                <div className="font-normal text-[14px] leading-[1.55] text-[#121212]">
                                    {recoveryData.representativeName}
                                </div>
                            </div>
                            <div>
                                <div className="font-light text-[16px] leading-[1.55] text-[#595A5B]">
                                    Authorised representative Email ID
                                </div>
                                <div className="font-normal text-[14px] leading-[1.55] text-[#121212]">
                                    {recoveryData.representativeEmail}
                                </div>
                            </div>
                            <div>
                                <div className="font-light text-[16px] leading-[1.55] text-[#595A5B]">
                                    Business POC
                                </div>
                                <div className="font-normal text-[14px] leading-[1.55] text-[#121212]">
                                    {recoveryData.businessPOC ? recoveryData.businessPOC : "NA"}
                                </div>
                            </div>
                            <div>
                                <div className="font-light text-[16px] leading-[1.55] text-[#595A5B]">
                                    Registered Address
                                </div>
                                <div className="font-normal text-[14px] leading-[1.55] text-[#121212]">
                                    {recoveryData.registeredAddress
                                        ? recoveryData.registeredAddress
                                        : "NA"}
                                </div>
                            </div>
                            <div>
                                <div className="font-light text-[16px] leading-[1.55] text-[#595A5B]">
                                    Corporate Address
                                </div>
                                <div className="font-normal text-[14px] leading-[1.55] text-[#121212]">
                                    {recoveryData.corporateAddress
                                        ? recoveryData.corporateAddress
                                        : "NA"}
                                </div>
                            </div>
                        </>
                    )}
                    {recoveryData.type === "Individual" && (
                        <>
                            <div>
                                <div className="font-light text-[16px] leading-[1.55] text-[#595A5B]">
                                    Individual Name
                                </div>
                                <div className="font-normal text-[14px] leading-[1.55] text-[#121212]">
                                    {recoveryData.individualName}
                                </div>
                            </div>
                            <div>
                                <div className="font-light text-[16px] leading-[1.55] text-[#595A5B]">
                                    Email ID
                                </div>
                                <div className="font-normal text-[14px] leading-[1.55] text-[#121212]">
                                    {recoveryData.emailID}
                                </div>
                            </div>
                            <div>
                                <div className="font-light text-[16px] leading-[1.55] text-[#595A5B]">
                                    Business POC
                                </div>
                                <div className="font-normal text-[14px] leading-[1.55] text-[#121212]">
                                    {recoveryData.businessPOC ? recoveryData.businessPOC : "NA"}
                                </div>
                            </div>
                            <div>
                                <div className="font-light text-[16px] leading-[1.55] text-[#595A5B]">
                                    Father's Name
                                </div>
                                <div className="font-normal text-[14px] leading-[1.55] text-[#121212]">
                                    {recoveryData.fathersName ? recoveryData.fathersName : "NA"}
                                </div>
                            </div>
                            <div>
                                <div className="font-light text-[16px] leading-[1.55] text-[#595A5B]">
                                    Residence Address
                                </div>
                                <div className="font-normal text-[14px] leading-[1.55] text-[#121212]">
                                    {recoveryData.residenceAddress
                                        ? recoveryData.residenceAddress
                                        : "NA"}
                                </div>
                            </div>
                            <div>
                                <div className="font-light text-[16px] leading-[1.55] text-[#595A5B]">
                                    PAN No.
                                </div>
                                <div className="font-normal text-[14px] leading-[1.55] text-[#121212]">
                                    {recoveryData.panNo ? recoveryData.panNo : "NA"}
                                </div>
                            </div>
                            <div>
                                <div className="font-light text-[16px] leading-[1.55] text-[#595A5B]">
                                    Phone No.
                                </div>
                                <div className="font-normal text-[14px] leading-[1.55] text-[#121212]">
                                    {recoveryData.phoneNo ? recoveryData.phoneNo : "NA"}
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </div>
            <div>
                <div
                    className="font-normal text-[20px] leading-[1.55] text-black mt-4"
                    style={{ color: "#46BDE1" }}
                >
                    Due Details
                </div>
                <div className="grid grid-cols-4 gap-y-9 gap-x-4 mt-3 mb-9">
                    <div>
                        <div className="font-light text-[16px] leading-[1.55] text-[#595A5B]">
                            Recovery Amount
                        </div>
                        <div className="font-normal text-[14px] leading-[1.55] text-[#121212] ">
                            {recoveryData.DueDetail
                                ? formatNumberWithCommas(recoveryData.DueDetail[0].recoveryAmount)
                                : "NA"}
                        </div>
                    </div>
                    <div>
                        <div className="font-light text-[16px] leading-[1.55] text-[#595A5B]">
                            Currency Type
                        </div>
                        <div className="font-normal text-[14px] leading-[1.55] text-[#121212]">
                            {recoveryData.DueDetail
                                ? recoveryData.DueDetail[0].currencyType
                                : "NA"}
                        </div>
                    </div>
                    <div>
                        <div className="font-light text-[16px] leading-[1.55] text-[#595A5B]">
                            Number of Invoice
                        </div>
                        <div className="font-normal text-[14px] leading-[1.55] text-[#121212]">
                            {recoveryData.DueDetail
                                ? recoveryData.DueDetail[0].noOfInvoice
                                : "NA"}
                        </div>
                    </div>
                    <div>
                        <div className="font-light text-[16px] leading-[1.55] text-[#595A5B]">
                            First Invoice Date
                        </div>
                        <div className="font-normal text-[14px] leading-[1.55] text-[#121212]">
                            {recoveryData.DueDetail
                                ? formatDate(recoveryData.DueDetail[0].firstInvDate)
                                : "NA"}
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div
                    className="font-normal text-[20px] leading-[1.55] text-black mt-4"
                    style={{ color: "#46BDE1" }}
                >
                    Invoice Details
                </div>
                {recoveryData.DueDetail && (
                    <InvoiceListSection dueDetailId={recoveryData.DueDetail[0].id} />
                )}
                <div
                    className="font-normal text-[20px] leading-[1.55] text-black mt-10 mb-4"
                    style={{ color: "#46BDE1" }}
                >
                    Action Details
                </div>
                {recoveryData.DueDetail && (
                    <ActionsPage
                        recoveryId={recoveryData.id}
                        dueDetailId={recoveryData.DueDetail[0].id}
                        isViewPage={true}
                        haveActionRight={!isViewOnly}
                    />
                )}
                <div
                    className="font-normal text-[20px] leading-[1.55] text-black mt-10 mb-4"
                    style={{ color: "#46BDE1" }}
                >
                    Assigned Authority
                </div>
                <div className="grid grid-cols-4 gap-y-9 gap-x-4 mt-3 mb-9">
                    <div>
                        <div className="font-light text-[16px] leading-[1.55] text-[#595A5B]">
                            Initiator
                        </div>
                        <div className="font-normal text-[14px] leading-[1.55] text-[#121212]">
                            {userAccessData.initiator
                                ? userAccessData.initiator.length &&
                                userAccessData.initiator.map((initiator, index) => (
                                    <Badge
                                        key={index}
                                        className="text-s m-[4px] lowercase"
                                        title={initiator.email}
                                    >
                                        {initiator.email}
                                    </Badge>
                                ))
                                : "NA"}
                        </div>
                    </div>
                    <div>
                        <div className="font-light text-[16px] leading-[1.55] text-[#595A5B]">
                            Editor
                        </div>
                        <div className="font-normal text-[14px] leading-[1.55] text-[#121212]">
                            {userAccessData.editor
                                ? userAccessData.editor.length &&
                                userAccessData.editor.map((editor, index) => (
                                    <Badge
                                        key={index}
                                        className="text-s m-[4px] lowercase"
                                        title={editor.email}
                                    >
                                        {editor.email}
                                    </Badge>
                                ))
                                : "NA"}
                        </div>
                    </div>
                    <div>
                        <div className="font-light text-[16px] leading-[1.55] text-[#595A5B]">
                            Approver
                        </div>
                        <div className="font-normal text-[14px] leading-[1.55] text-[#121212]">
                            {userAccessData.approver
                                ? userAccessData.approver.length &&
                                userAccessData.approver.map((approver, index) => (
                                    <Badge
                                        key={index}
                                        className="text-s m-[4px] lowercase max-w-[130px] truncate"
                                        title={approver.email}
                                    >
                                        {approver.email}
                                    </Badge>
                                ))
                                : "NA"}
                        </div>
                    </div>
                </div>
                {recoveryData.DisposeData && recoveryData.DisposeData.length > 0 && (
                    <>
                        <div
                            className="font-normal text-[20px] leading-[1.55] text-black mt-10 mb-4"
                            style={{ color: "#46BDE1" }}
                        >
                            Dispose Details
                        </div>
                        <DisposeDisplayComponent formState={recoveryData.DisposeData[0]} />
                    </>
                )}
            </div>
            <div className="flex flex-col mt-10">
                <Tabs value={activeTab} onTabChange={setActiveTab}>
                    <Tabs.List>
                        <Tabs.Tab value="notice">Notices</Tabs.Tab>
                        <Tabs.Tab value="email">Emails</Tabs.Tab>
                        <Tabs.Tab value="docs">Documents</Tabs.Tab>
                        <Tabs.Tab value="expense">Expenses</Tabs.Tab>
                        <Tabs.Tab value="sentEmail">Sent Emails</Tabs.Tab>
                        <Tabs.Tab value="activity">Activity Logs</Tabs.Tab>
                    </Tabs.List>
                    <Tabs.Panel value="notice">
                        <NoticeComponent recoveryId={recoveryData.id} haveActionRight={!isViewOnly} />
                    </Tabs.Panel>
                    <Tabs.Panel value="email">
                        <EmailComponent recoveryId={recoveryData.id} haveActionRight={!isViewOnly} />
                    </Tabs.Panel>
                    <Tabs.Panel value="docs">
                        {recoveryData.DueDetail && (
                            <DocsComponent dueDetailId={recoveryData.DueDetail[0].id} />
                        )}
                    </Tabs.Panel>
                    <Tabs.Panel value="expense">
                        <ExpensesTab parent="recovery" parentId={recoveryData.id} />
                    </Tabs.Panel>
                    <Tabs.Panel value="sentEmail">
                        {recoveryData.Actions &&
                            recoveryData.Actions.length > 0 &&
                            recoveryData.Actions.map((action) => (
                                <EmailSentComponent parent={"action"} parentId={action.id} />
                            ))}
                        {/* <EmailSentComponent recoveryId={recoveryData.id} parent={'action'} parentId={3} /> */}
                    </Tabs.Panel>
                    <Tabs.Panel value="activity">
                        <ActivityComponent recoveryId={recoveryData.id} />
                    </Tabs.Panel>
                </Tabs>
            </div>
            <div className="flex flex-row justify-center items-center mt-10">
                <Button
                    color={currentUser.loginAs.name === "Editor" ? "red" : "green"}
                    onClick={() =>
                        setRecoveryAuditData({
                            ...recoveryAuditData,
                            isModalOpen: true,
                        })
                    }
                >
                    {currentUser.loginAs.name === "Editor"
                        ? "Send to Initiator"
                        : "Send to Editor"}
                </Button>
            </div>
        </>
    );
};

export default RecoveryDisplayPage;
