import React, { useState, useEffect } from "react";
import {
    Table,
    ActionIcon,
    Text,
    Select,
    TextInput,
    Button,
    Pagination,
} from "@mantine/core";
import { useNavigate } from "react-router-dom";
import { Download, Search, SortAscending } from "tabler-icons-react";
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import { apiWrapWithErrorWithData } from "../../utilities/apiHelpers";
import { getRecoveryDashboardTable } from "../../utilities/apis/recovery";
import DashboardTableComponent from "./DashboardTableComponent";

const DashboardTableDisplay = () => {
    const [formState, setFormState] = useState({
        loading: false,
        tableList: [],
        filter: {
            status: "All",
            searchText: "",
            sortType: "DESC",
            orderByColumn: "",
            lessThanAmount: "",
            greaterThanAmount: "",
        },
        pageNo: 1,
        totalDataCount: 0,
        apiCall: true,
    });
    const navigate = useNavigate();
    const fetchDetails = async () => {
        const payload = {
            filter: formState.filter,
            pageNo: formState.pageNo,
        };
        const response = await apiWrapWithErrorWithData(
            getRecoveryDashboardTable(payload)
        );
        if (response.success) {
            console.log("response dashboard table -------->>", response);
            setFormState((prevState) => ({
                ...prevState,
                tableList: response.data.recoveryList,
                totalDataCount:
                    response.data.recoveryList.length > 0
                        ? response.data.recoveryList[0].totalDataCount
                        : 0,
            }));
        }
    };
    useEffect(() => {
        if (formState.apiCall) {
            fetchDetails();
            setFormState((prevState) => ({
                ...prevState,
                apiCall: false,
            }));
        }
    }, [formState.apiCall]);
    const handleClearClick = async () => {
        console.log("clear clicked", formState);
        setFormState({
            ...formState,
            filter: {
                status: "All",
                searchText: "",
                sortType: "DESC",
                orderByColumn: "",
                lessThanAmount: "",
                greaterThanAmount: "",
            },
            pageNo: 1,
            apiCall: true,
        });
    };
    const handleExcelDownload = async () => {
        const mappedData = formState.tableList.map(item => ({
            "Ref no": `recovery-${item.id}`,
            "Notice Count": item.noticeCount,
            "Debtor Name": item.debtorName,
            "Business Unit": item.businessUnit,
            "Total Amount": item.totalAmount,
            "Invoice Amount": item.invoiceAmount,
            "Status": item.status,
            "Ageing": item.ageing
        }));
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Sheet1');
        worksheet.columns = [
            { header: 'Ref no.', key: 'Ref no', width: 15 },
            { header: 'Notice Count', key: 'Notice Count', width: 15 },
            { header: 'Debtor Name', key: 'Debtor Name', width: 20 },
            { header: 'Business Unit', key: 'Business Unit', width: 20 },
            { header: 'Total Amount', key: 'Total Amount', width: 15 },
            { header: 'Invoice Amount', key: 'Invoice Amount', width: 15 },
            { header: 'Status', key: 'Status', width: 10 },
            { header: 'Ageing', key: 'Ageing', width: 10 }
        ];

        const headerRow = worksheet.getRow(1);
        headerRow.eachCell({ includeEmpty: true }, (cell, colNumber) => {
            if (colNumber <= 8) {
                cell.font = { bold: true, color: { argb: 'FFFFFFFF' } };
                cell.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'FFADD8E6' }
                };
                cell.border = {
                    top: { style: 'thin' },
                    left: { style: 'thin' },
                    bottom: { style: 'thin' },
                    right: { style: 'thin' }
                };
            }
        });
        mappedData.forEach((data) => {
            const row = worksheet.addRow(data);
            row.eachCell((cell) => {
                cell.border = {
                    top: { style: 'thin' },
                    left: { style: 'thin' },
                    bottom: { style: 'thin' },
                    right: { style: 'thin' }
                };
            });
        });
        const buffer = await workbook.xlsx.writeBuffer();
        const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        saveAs(blob, 'dashboard.xlsx');
    };
    return (
        <>
            <br />
            <br />
            <br />
            <div className="flex w-full justify-between items-center">
                <div className="flex flex-col">
                    <div className="flex flex-row-sm">
                        <div className="flex items-center">
                            <Select
                                className="mx-2 min-w-[225px]"
                                placeholder="Select recovery status"
                                data={[
                                    "All",
                                    "Active",
                                    "Pending",
                                    "Write off",
                                    "Settlement",
                                    "Case creation",
                                    "Fully Recovered",
                                ]}
                                value={formState.filter.status}
                                onChange={(value) => {
                                    setFormState({
                                        ...formState,
                                        filter: {
                                            ...formState.filter,
                                            status: value,
                                        },
                                    });
                                }}
                            />
                            <TextInput
                                className="mx-2 min-w-[225px]"
                                placeholder="Search text"
                                icon={<Search size={14} />}
                                value={formState.filter.searchText}
                                onChange={(e) => {
                                    setFormState({
                                        ...formState,
                                        filter: {
                                            ...formState.filter,
                                            searchText: e.currentTarget.value,
                                        },
                                    });
                                }}
                            />
                            <TextInput
                                className="mx-2 min-w-[225px]"
                                placeholder="Less than amount"
                                type="number"
                                value={formState.filter.lessThanAmount}
                                onChange={(e) => {
                                    setFormState({
                                        ...formState,
                                        filter: {
                                            ...formState.filter,
                                            lessThanAmount: e.currentTarget.value,
                                        },
                                    });
                                }}
                            />
                            <TextInput
                                className="mx-2 min-w-[225px]"
                                placeholder="Greater than amount"
                                type="number"
                                value={formState.filter.greaterThanAmount}
                                onChange={(e) => {
                                    setFormState({
                                        ...formState,
                                        filter: {
                                            ...formState.filter,
                                            greaterThanAmount: e.currentTarget.value,
                                        },
                                    });
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <br />
            <Button
                className="mx-2"
                color="green"
                onClick={() =>
                    setFormState({
                        ...formState,
                        pageNo: 1,
                        apiCall: true,
                    })
                }
            >
                Search
            </Button>
            <Button className="mx-2" color="red" onClick={handleClearClick}>
                Clear
            </Button>
            <br />
            <br />
            <div className="flex items-center justify-end">
                Sort:
                <Select
                    placeholder="Sort by"
                    className="mx-2"
                    value={formState.filter.orderByColumn}
                    onChange={(value) => {
                        setFormState({
                            ...formState,
                            filter: {
                                ...formState.filter,
                                orderByColumn: value,
                            },
                            apiCall: true,
                        });
                    }}
                    data={[
                        { label: "Recent", value: "" },
                        { label: "Reference no.", value: "r.id" },
                        { label: "Notice Count", value: "noticeCount" },
                        { label: "Debtor Name", value: "debtorName" },
                        { label: "Business Unit", value: "businessUnit" },
                        { label: "Invoice Amount", value: "invoiceAmount" },
                        { label: "Total Amount", value: "totalAmount" },
                        { label: "Status", value: "status" },
                        { label: "Ageing", value: "ageing" },
                    ]}
                />
                <ActionIcon
                    onClick={() => {
                        setFormState((prevState) => ({
                            ...prevState,
                            filter: {
                                ...prevState.filter,
                                sortType: prevState.filter.sortType === "ASC" ? "DESC" : "ASC",
                            },
                            apiCall: true,
                        }));
                    }}
                    color="blue"
                    className={formState.filter.sortType === "ASC" ? "" : "rotate-180"}
                >
                    <SortAscending />
                </ActionIcon>
                <ActionIcon
                    className="ml-5"
                    title="Excel Download"
                    onClick={handleExcelDownload}
                >
                    <Download color="green" />
                </ActionIcon>
            </div>
            <div className="mt-10">
                <DashboardTableComponent
                    tableData={formState.tableList}
                    tableCount={formState.totalDataCount}
                    pageNo={formState.pageNo}
                    setFormState={setFormState}
                />
            </div>
        </>
    );
};

export default DashboardTableDisplay;
