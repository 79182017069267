import React from "react";
import { Badge } from "@mantine/core";
import { formatDate, formatNumberWithCommas } from "../../utilities/utilities";
import NewDocumentUploader from "../NewDocumentUploader/NewDocumentUploader";
import useMultiFileUpload from "../../hooks/useMultiFileUpload";

const DisposeDisplayComponent = ({ formState }) => {
    const multiUploadArgs = useMultiFileUpload({
        loadFromParent: true,
        parent: "disposeData",
        parentId: formState.id,
    });

    return (
        <>
            {formState.optSelected === "Write off" && (
                <>
                    <div className="grid grid-cols-4 gap-y-9 gap-x-4 mt-3 mb-9">
                        <div>
                            <div className="font-light text-[16px] leading-[1.55] text-[#595A5B]">
                                Type
                            </div>
                            <div className="font-normal text-[14px] leading-[1.55] text-[#121212]">
                                Write off
                            </div>
                        </div>
                        <div>
                            <div className="font-light text-[16px] leading-[1.55] text-[#595A5B]">
                                Approvers
                            </div>
                            <div className="font-normal text-[14px] leading-[1.55] text-[#121212]">
                                {formState.approvers.map((approver) => (
                                    <Badge
                                        key={approver}
                                        className="text-s m-[4px] lowercase max-w-[130px] truncate"
                                        title={approver}
                                    >
                                        {approver}
                                    </Badge>
                                ))}
                            </div>
                        </div>
                        <div className="col-span-2">
                            <div className="font-light text-[16px] leading-[1.55] text-[#595A5B]">
                                Reason
                            </div>
                            <div className="font-normal text-[14px] leading-[1.55] text-[#121212]">
                                {formState.reason}
                            </div>
                        </div>
                    </div>
                </>
            )}
            {formState.optSelected === "Settlement" && (
                <>
                    <div className="grid grid-cols-2 gap-y-9 gap-x-4 mt-3 mb-9">
                        <div className="grid grid-cols-2 gap-y-9 gap-x-4">
                            <div>
                                <div className="font-light text-[16px] leading-[1.55] text-[#595A5B]">
                                    Type
                                </div>
                                <div className="font-normal text-[14px] leading-[1.55] text-[#121212]">
                                    Settlement
                                </div>
                            </div>
                            <div>
                                <div className="font-light text-[16px] leading-[1.55] text-[#595A5B]">
                                    Settlement Amount
                                </div>
                                <div className="font-normal text-[14px] leading-[1.55] text-[#121212]">
                                    {formatNumberWithCommas(formState.sAmount)}
                                </div>
                            </div>
                            <div>
                                <div className="font-light text-[16px] leading-[1.55] text-[#595A5B]">
                                    Settlement Date
                                </div>
                                <div className="font-normal text-[14px] leading-[1.55] text-[#121212]">
                                    {formatDate(formState.sDate)}
                                </div>
                            </div>
                            <div>
                                <div className="font-light text-[16px] leading-[1.55] text-[#595A5B]">
                                    Approvers
                                </div>
                                <div className="font-normal text-[14px] leading-[1.55] text-[#121212]">
                                    {formState.approvers.map((approver) => (
                                        <Badge
                                            key={approver}
                                            className="text-s m-[4px] lowercase max-w-[130px] truncate"
                                            title={approver}
                                        >
                                            {approver}
                                        </Badge>
                                    ))}
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="font-light text-[16px] leading-[1.55] text-[#595A5B]">
                                Summary
                            </div>
                            <div className="font-normal text-[14px] leading-[1.55] text-[#121212]">
                                {formState.reason}
                            </div>
                        </div>
                        <div className="col-span-2">
                            <div className="font-light text-[16px] leading-[1.55] text-[#595A5B]">
                                Attachments
                            </div>
                            <div className="font-normal text-[14px] leading-[1.55] text-[#121212]">
                                <NewDocumentUploader
                                    hideDeleteButton
                                    hideDropZone
                                    multiUploadArgs={multiUploadArgs}
                                />
                            </div>
                        </div>
                    </div>
                </>
            )}
            {formState.optSelected === "Case Creation" && (
                <>
                    <div className="grid grid-cols-4 gap-y-9 gap-x-4 mt-3 mb-9">
                        <div>
                            <div className="font-light text-[16px] leading-[1.55] text-[#595A5B]">
                                Type
                            </div>
                            <div className="font-normal text-[14px] leading-[1.55] text-[#121212]">
                                Case Creation
                            </div>
                        </div>
                        <div>
                            <div className="font-light text-[16px] leading-[1.55] text-[#595A5B]">
                                Approvers
                            </div>
                            <div className="font-normal text-[14px] leading-[1.55] text-[#121212]">
                                {formState.approvers.map((approver) => (
                                    <Badge
                                        key={approver}
                                        className="text-s m-[4px] lowercase max-w-[130px] truncate"
                                        title={approver}
                                    >
                                        {approver}
                                    </Badge>
                                ))}
                            </div>
                        </div>
                        <div className="col-span-2">
                            <div className="font-light text-[16px] leading-[1.55] text-[#595A5B]">
                                Reason
                            </div>
                            <div className="font-normal text-[14px] leading-[1.55] text-[#121212]">
                                {formState.reason}
                            </div>
                        </div>
                    </div>
                </>
            )}
            {formState.optSelected === "Fully Recovered" && (
                <>
                    <div className="grid grid-cols-4 gap-y-9 gap-x-4 mt-3 mb-9">
                        <div>
                            <div className="font-light text-[16px] leading-[1.55] text-[#595A5B]">
                                Type
                            </div>
                            <div className="font-normal text-[14px] leading-[1.55] text-[#121212]">
                                Completed
                            </div>
                        </div>
                        <div>
                            <div className="font-light text-[16px] leading-[1.55] text-[#595A5B]">
                                Payment Status
                            </div>
                            <div className="font-normal text-[14px] leading-[1.55] text-[#121212] mt-1">
                                {formState.cIsPaid === true ? (
                                    <Badge color="green">Paid</Badge>
                                ) : (
                                    <Badge color="red">Pending</Badge>
                                )}
                            </div>
                        </div>
                        <div>
                            <div className="font-light text-[16px] leading-[1.55] text-[#595A5B]">
                                Closing Date
                            </div>
                            <div className="font-normal text-[14px] leading-[1.55] text-[#121212]">
                                {formatDate(formState.cDate)}
                            </div>
                        </div>
                    </div>
                </>
            )}
        </>
    );
};

export default DisposeDisplayComponent;
