import React, { useState, useEffect } from "react";
import { Stepper, Button } from "@mantine/core";
import { useParams, useNavigate } from 'react-router-dom';
import colors, { themeColor } from "../../utilities/design";
import PartyDetailPage from "./PartyDetailPage";
import DueDetailPage from "./DueDetailPage";
import ActionsPage from "./ActionsPage";
import UserRolePage from "./UserRolePage";
import { apiWrapWithErrorWithData } from "../../utilities/apiHelpers";
import {
  createRecovery,
  createDueDetail,
  updateRecovery,
  updateDueDetail,
  getSpecificRecoveryDetail,
  registerUserAccess
} from "../../utilities/apis/recovery";
import { validateEmail } from "../../utilities/utilities";
import { showNotification } from "@mantine/notifications";
import { useSelector } from "react-redux";
import { selectUserData } from "../../redux/selectors";

function NewRecovery() {
  const { id } = useParams();
  const [currentStep, setCurrentStep] = useState(0);
  const currentUser = useSelector(selectUserData) || {};
  const [partyFormState, setPartyFormState] = useState({
    id: 0,
    isExistingAgreement: false,
    type: "Entity",
    entityType: "",
    registeredName: "",
    representativeName: "",
    representativeEmail: "",
    businessUnit: "",
    businessPOC: "",
    registeredAddress: "",
    corporateAddress: "",
    individualName: "",
    emailID: "",
    fathersName: "",
    residenceAddress: "",
    panNo: "",
    phoneNo: "",
    errors: {},
  });
  const [dueFormState, setDueFormState] = useState({
    id: 0,
    recoveryAmount: "",
    currencyType: "",
    noOfInvoice: "",
    firstInvDate: "",
    files: "",
    recoveryId: 0,
    errors: {},
  });
  const [userRoleFormState, setUserRoleFormState] = useState({
    initiator: [],
    editor: [],
    approver: [],
    errors: {},
  });

  const navigate = useNavigate();
  const fetchRecovery = async () => {
    const response = await apiWrapWithErrorWithData(
      getSpecificRecoveryDetail({ recoveryId: id })
    );
    if (response.success) {
      setPartyFormState((prevState) => ({
        ...prevState,
        id: response.data.recovery.id,
        isExistingAgreement: response.data.recovery.isExistingAgreement,
        type: response.data.recovery.type,
        entityType: response.data.recovery.entityType,
        registeredName: response.data.recovery.registeredName,
        representativeName: response.data.recovery.representativeName,
        representativeEmail: response.data.recovery.representativeEmail,
        businessUnit: parseInt(response.data.recovery.businessUnit, 10),
        businessPOC: response.data.recovery.businessPOC,
        registeredAddress: response.data.recovery.registeredAddress,
        corporateAddress: response.data.recovery.corporateAddress,
        individualName: response.data.recovery.individualName,
        emailID: response.data.recovery.emailID,
        fathersName: response.data.recovery.fathersName,
        residenceAddress: response.data.recovery.residenceAddress,
        panNo: response.data.recovery.panNo,
        phoneNo: response.data.recovery.phoneNo,
      }));
      if (response.data.recovery.DueDetail.length > 0) {
        // let formatedDate = response.data.recovery.DueDetail[0].firstInvDate ? new Date(response.data.recovery.DueDetail[0].firstInvDate) : '';
        // if (formatedDate) {
        //   formatedDate = formatedDate.setDate(formatedDate.getDate() - 1);
        // }
        setDueFormState((prevState) => ({
          ...prevState,
          id: response.data.recovery.DueDetail[0].id,
          recoveryAmount: response.data.recovery.DueDetail[0].recoveryAmount,
          currencyType: response.data.recovery.DueDetail[0].currencyType,
          noOfInvoice: response.data.recovery.DueDetail[0].noOfInvoice,
          firstInvDate: response.data.recovery.DueDetail[0].firstInvDate,
          recoveryId: response.data.recovery.id,
        }));
      }
      if (response.data.recovery.userAccessJson) {
        setUserRoleFormState((prevState) => ({
          ...prevState,
          initiator: response.data.recovery.userAccessJson.initiator,
          editor: response.data.recovery.userAccessJson.editor,
          approver: response.data.recovery.userAccessJson.approver,
        }));
      }
    }
  }

  const validatePartyForm = () => {
    let tempErrors = {};
    if (partyFormState.type === "Entity") {
      if (!partyFormState.entityType) {
        tempErrors.entityType = "Entity Type is required";
      }
      if (partyFormState.registeredName.length === 0) {
        tempErrors.registeredName = "Registered Name is required";
      }
      if (!partyFormState.representativeName) {
        tempErrors.representativeName = "Representative Name is required";
      }
      if (!partyFormState.businessUnit) {
        tempErrors.businessUnit = "Business Unit is required";
      }
      if (
        !partyFormState.representativeEmail ||
        !validateEmail(partyFormState.representativeEmail)
      ) {
        tempErrors.representativeEmail = "Representative Email is required";
      }
    } else {
      if (!partyFormState.individualName) {
        tempErrors.individualName = "Individual Name is required";
      }
      if (!partyFormState.emailID || !validateEmail(partyFormState.emailID)) {
        tempErrors.emailID = "Email ID is required";
      }
      if (!partyFormState.businessUnit) {
        tempErrors.businessUnit = "Business Unit is required";
      }
    }

    setPartyFormState((prevState) => ({
      ...prevState,
      errors: tempErrors,
    }));
    return tempErrors;
  };

  const validateDueForm = () => {
    let tempErrors = {};
    if (!dueFormState.recoveryAmount) {
      tempErrors.recoveryAmount = "Recovery Amount is required";
    }
    if (!dueFormState.currencyType) {
      tempErrors.currencyType = "Currency Type is required";
    }
    if (!dueFormState.noOfInvoice) {
      tempErrors.noOfInvoice = "Invoice Number is required";
    }
    if (!dueFormState.firstInvDate) {
      tempErrors.firstInvDate = "Due Date is required";
    }

    setDueFormState((prevState) => ({
      ...prevState,
      errors: tempErrors,
    }));
    return tempErrors;
  };

  const validateUserRoleForm = () => {
    let tempErrors = {};
    if (userRoleFormState.initiator.length === 0) {
      tempErrors.initiator = "Initiator is required";
    }
    if (userRoleFormState.editor.length === 0) {
      tempErrors.editor = "Editor is required";
    }
    if (userRoleFormState.approver.length === 0) {
      tempErrors.approver = "Approver is required";
    }
    setUserRoleFormState((prevState) => ({
      ...prevState,
      errors: tempErrors,
    }));
    return Object.keys(tempErrors).length === 0;
  };

  const handleNextClick = async () => {
    let errors = {};
    if (currentStep === 0) {
      errors = validatePartyForm();
      if (Object.keys(errors).length === 0) {
        try {
          if (partyFormState.id === 0) {
            partyFormState.isEditor = currentUser.loginAs.name === "Editor"
            const response = await apiWrapWithErrorWithData(
              createRecovery(partyFormState)
            );
            if (response?.success) {
              setPartyFormState((prevState) => ({
                ...prevState,
                id: response.recovery.id,
              }));
              setDueFormState((prevState) => ({
                ...prevState,
                recoveryId: response.recovery.id,
              }));
              setCurrentStep(currentStep + 1);
            }
          } else {
            const response = await apiWrapWithErrorWithData(
              updateRecovery(partyFormState)
            );
            if (response?.success) {
              setCurrentStep(currentStep + 1);
            }
          }
        } catch (error) {
          console.error("Error creating recovery:", error);
        }
      }
    } else if (currentStep === 1) {
      console.log("dueFormState", dueFormState);
      errors = validateDueForm();
      if (Object.keys(errors).length === 0) {
        try {
          if (dueFormState.id === 0) {
            const response = await apiWrapWithErrorWithData(
              createDueDetail(dueFormState)
            );
            if (response?.success) {
              setDueFormState((prevState) => ({
                ...prevState,
                id: response.dueDetail.id,
              }));
              setCurrentStep(currentStep + 1);
            }
          } else {
            const response = await apiWrapWithErrorWithData(
              updateDueDetail(dueFormState)
            );
            if (response?.success) {
              setCurrentStep(currentStep + 1);
            }
          }
        } catch (error) {
          console.error("Error creating due detail:", error);
        }
      }
    } else if (currentStep === 2) {
      setCurrentStep(currentStep + 1);
    }
  };

  const handleStepClick = id ? (step) => setCurrentStep(step) : undefined;

  const handleFinishClick = async () => {
    console.log("Finish Clicked");
    if (validateUserRoleForm()) {
      console.log("All validations passed");
      const payload = {
        recoveryId: dueFormState.recoveryId,
        userAccessJson: {
          initiator: userRoleFormState.initiator,
          editor: userRoleFormState.editor,
          reviewer: userRoleFormState.reviewer,
          approver: userRoleFormState.approver,
        }
      }
      const response = await apiWrapWithErrorWithData(registerUserAccess(payload));
      console.log("response", response);

      if (response.success) {
        navigate(`/app/recovery/${dueFormState.recoveryId}`);
      } else {
        showNotification({
          title: "Error",
          message: "Error while creating user roles",
          color: "red",
        })
      }
    }
  }

  useEffect(() => {
    if (id) {
      fetchRecovery();
    }
  }, []);

  return (
    <>
      <Stepper
        color={themeColor(colors.agreement)}
        active={currentStep}
        onStepClick={handleStepClick}
        breakpoint="sm"
      >
        <Stepper.Step allowStepSelect={true} label="debtor details">
          <PartyDetailPage
            formState={partyFormState}
            setFormState={setPartyFormState}
            errors={partyFormState.errors}
          />
        </Stepper.Step>
        <Stepper.Step allowStepSelect={true} label="recovery details">
          <DueDetailPage
            formState={dueFormState}
            setFormState={setDueFormState}
            errors={dueFormState.errors}
          />
        </Stepper.Step>
        <Stepper.Step allowStepSelect={true} label="actions">
          <ActionsPage recoveryId={dueFormState.recoveryId} dueDetailId={dueFormState.id} isViewPage={false} />
        </Stepper.Step>
        <Stepper.Step allowStepSelect={true} label="user roles">
          <UserRolePage recoveryId={dueFormState.recoveryId} formState={userRoleFormState} setFormState={setUserRoleFormState} />
        </Stepper.Step>
      </Stepper>
      <div className="flex flex-row justify-between">
        <Button
          className="max-w-xs mt-20"
          onClick={() => setCurrentStep(currentStep - 1)}
          disabled={currentStep === 0}
        >
          Previous
        </Button>
        {currentStep !== 3 ? <Button
          className="max-w-xs mt-20"
          onClick={handleNextClick}
          // onClick={() => setCurrentStep(currentStep + 1)}
          disabled={currentStep === 3}
        >
          Next
        </Button> :
          <Button
            className="max-w-xs mt-20"
            onClick={handleFinishClick}
          >
            Finish
          </Button>
        }
      </div>
    </>
  );
}

export default NewRecovery;
